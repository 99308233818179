export const populationVisibilityTypes = {
  FULL: { key: 'full', label: 'Full company' },
  RESTRICTED: { key: 'restricted', label: 'Custom access' },
  RESTRICTED_SELF: { key: 'restricted_self', label: 'Team only' },
};

export const fieldVisibilityTypes = {
  FULL: { key: 'full', label: 'Full data access' },
  RBAC: { key: 'restricted', label: 'Custom data access' },
  NOGROUP: { key: 'nogroup', label: 'No permission group' },
  NONE: { key: 'none', label: 'No data access' },
};

export const accountTypes = {
  ADMIN: 'admin',
  NORMAL: 'normal',
  GUEST: 'guest',
};
