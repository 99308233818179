import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import type { Dimension } from 'context/FiltersProvider';

import { AnalyticsUser } from 'hooks/useAnalyticsUsers';

import base from 'api/base';
import type { Field } from 'api/fieldsApi';
import { CONFIG_SERVICE_ROOT } from 'config';

import { addDataToPermissionGroup } from './utils';

export interface Permission {
  createdAt: string;
  createdBy: string;
  deletedAt: string;
  deletedBy: string;
  id: number;
  mapping: {
    field: Field;
    id: number;
    metadata: {
      useInBreakdownBy?: boolean;
      useInFilters?: boolean;
      useInGroupBy?: boolean;
    };
  };
}

export interface PreviewPermissionGroup {
  name: string;
  allowedKeys: number[];
  users: AnalyticsUser[];
  deleteUsers: string[];
}

export interface PermissionGroupUser {
  createdAt: string;
  createdBy: string;
  deletedAt: string;
  id: number;
  userId: string;
}

interface AddFieldsToPermissionGroup {
  id: number;
  mapping: {
    id: number;
  };
  group: PermissionGroup;
  createdAt: string;
  createdBy: string;
  deletedAt: string;
  deletedBy: string;
}

export interface PermissionGroup {
  createdAt: string;
  createdBy: string;
  deletedAt: string | null;
  id: number;
  name: string;
  updatedAt: string;
  updatedBy: string | null;
  permissions: Permission[];
  users: AnalyticsUser[];
  missingFields?: Dimension[];
  hiddenFields?: Dimension[];
  hiddenFieldsSearchableString?: string;
}

interface AddUsersToPermissionGroupPayload {
  users: string[];
  groupId: number;
}

export const getActivePermissionGroups = async (): Promise<PermissionGroup[]> => {
  const response = await base.api.get<never, PermissionGroup[]>(`${CONFIG_SERVICE_ROOT}/v2/config/companies/groups`);
  return response;
};

export const getPermissionGroupById = async (id: string): Promise<PermissionGroup> => {
  const response = await base.api.get<never, PermissionGroup>(`${CONFIG_SERVICE_ROOT}/v2/config/companies/group/${id}`);
  return response;
};

export const updatePermissionGroupById = async (id: number, name: string): Promise<PermissionGroup> => {
  const response = await base.api.patch<never, PermissionGroup>(
    `${CONFIG_SERVICE_ROOT}/v2/config/companies/group/${id}`,
    { name },
  );
  return response;
};

export const deletePermissionGroupById = async (id: string): Promise<PermissionGroup> => {
  const response = await base.api.delete<never, PermissionGroup>(
    `${CONFIG_SERVICE_ROOT}/v2/config/companies/group/${id}`,
  );
  return response;
};

export const createPermissionGroup = async (groupName: string): Promise<PermissionGroup> => {
  const response = await base.api.post<never, PermissionGroup>(`${CONFIG_SERVICE_ROOT}/v2/config/companies/group`, {
    name: groupName,
  });
  return response;
};

export const addFieldsToPermissionGroup = async (
  allowedFields: number[],
  groupId: number,
): Promise<AddFieldsToPermissionGroup[]> => {
  const response = await base.api.post<never, AddFieldsToPermissionGroup[]>(
    `${CONFIG_SERVICE_ROOT}/v2/config/companies/group/${groupId}/permissions`,
    {
      fields: allowedFields,
    },
  );
  return response;
};

export const addUsersToPermissionGroup = async (payload: AddUsersToPermissionGroupPayload) => {
  const response = await base.api.post<never, PermissionGroupUser[]>(
    `${CONFIG_SERVICE_ROOT}/v2/config/companies/group/${payload.groupId}/users`,
    {
      users: payload.users,
    },
  );
  return response;
};

export const deleteUserFromPermissionGroup = async (userId: string, groupId: number) => {
  const response = await base.api.delete(`${CONFIG_SERVICE_ROOT}/v2/config/companies/group/${groupId}/users`, {
    data: { users: [userId] },
  });
  return response;
};

// Queries
export const ALL_PERMISSION_GROUPS_QUERY_KEY = 'permissionGroups';

export const usePermissionGroupsQuery = ({
  availableDimensions,
  allUsers,
  enabled = true,
}: {
  availableDimensions: Dimension[];
  allUsers: AnalyticsUser[];
  enabled: boolean;
}) => {
  return useQuery<PermissionGroup[]>([ALL_PERMISSION_GROUPS_QUERY_KEY], () => getActivePermissionGroups(), {
    enabled,
    select: (response): PermissionGroup[] => {
      if (!response) return [];

      return response.map((permissionGroup) => {
        return addDataToPermissionGroup({ permissionGroup, availableDimensions, allUsers });
      });
    },
  });
};

export const useAddUsersToPermissionGroupMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((payload: AddUsersToPermissionGroupPayload) => addUsersToPermissionGroup(payload), {
    onSuccess: () => {
      void queryClient.invalidateQueries([ALL_PERMISSION_GROUPS_QUERY_KEY]);
    },
  });
};

export default {
  getActivePermissionGroups,
  createPermissionGroup,
  addFieldsToPermissionGroup,
  addUsersToPermissionGroup,
  getPermissionGroupById,
  updatePermissionGroupById,
  deletePermissionGroupById,
  deleteUserFromPermissionGroup,
};
