import type { Dimension } from 'context/FiltersProvider';

import { AnalyticsUser } from 'hooks/useAnalyticsUsers';

import { PermissionGroup } from '.';

export const addDataToPermissionGroup = ({
  permissionGroup,
  availableDimensions,
  allUsers,
}: {
  permissionGroup: PermissionGroup;
  availableDimensions: Dimension[];
  allUsers: AnalyticsUser[];
}) => {
  const { users: groupUsers, permissions } = permissionGroup;

  const allowedFields = permissions.map(
    ({ mapping }) => `${mapping.field.schema.toLowerCase()}.${mapping.field.fieldKey.toLowerCase()}`,
  );

  const hiddenFields = availableDimensions?.filter(
    ({ name, meta }) => meta?.mapId && !allowedFields.includes(name.toLowerCase()),
  );

  return {
    ...permissionGroup,
    hiddenFields,
    users: allUsers.filter(({ auth0UserId, email }) =>
      groupUsers.some(({ userId }) => userId === auth0UserId || userId === email),
    ),
  };
};
