import React, { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import FullPageLoader from 'components/FullPageLoader';

import MemberProvider from 'context/MemberProvider';
import UserProvider from 'context/UserProvider';

import httpClient from 'api/base';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.min.css';
import 'styles/index.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'styles/tailwind.scss';

const PublicApp = React.lazy(() => import('./PublicApp'));
const AuthenticatedApp = React.lazy(() => import('./AuthenticatedApp'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  const { isLoading, getAccessTokenSilently, logout, isAuthenticated } = useAuth0();

  // TODO: Consider refactoring httpClient - use an Auth0 global singleton instead
  // Require to allow our API layer to retrieve accessToken and auth0 logout method
  useEffect(() => {
    httpClient.setTokenGenerator(getAccessTokenSilently);
    httpClient.setLogoutMethod(logout);
  }, [getAccessTokenSilently, logout]);

  if (isLoading) return <FullPageLoader loadingText="Loading..." />;

  return (
    <React.Suspense fallback={<FullPageLoader loadingText="Loading..." />}>
      {isAuthenticated ? (
        <UserProvider>
          <MemberProvider>
            <QueryClientProvider client={queryClient}>
              <AuthenticatedApp />
            </QueryClientProvider>
          </MemberProvider>
        </UserProvider>
      ) : (
        <PublicApp />
      )}
    </React.Suspense>
  );
};

export default App;
