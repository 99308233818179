import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { omit } from 'lodash';

import { COMPANY_KEY } from 'constants/storageKeyVal';

import base from 'api/base';
import { deleteUserFromPermissionGroup } from 'api/permissionGroupsApi';
import { API_ROOT, CONFIG_SERVICE_ROOT } from 'config';

const USERS_QUERY_KEY = 'users';

// Color Schemes
export const getCompanyConfigurations = async () => {
  const company = sessionStorage.getItem(COMPANY_KEY);
  const response = await base.api.get(`${API_ROOT}/company/${company}/configuration`);
  return response;
};

export const updateConfigurations = async (configurations) => {
  const company = sessionStorage.getItem(COMPANY_KEY);
  const response = await base.api.patch(`${API_ROOT}/company/${company}/configuration`, configurations);
  return response;
};

// User Management
export const getUsers = async (query) => {
  const company = sessionStorage.getItem(COMPANY_KEY);
  const response = await base.api.get(`${API_ROOT}/company/${company}/users`, {
    params: query,
  });
  return response;
};

export const getUserInvitations = async () => {
  const company = sessionStorage.getItem(COMPANY_KEY);
  const response = await base.api.get(`${API_ROOT}/company/${company}/invitations`);
  return response;
};

const revokeUserInvitation = async (invitationId) => {
  const company = sessionStorage.getItem(COMPANY_KEY);
  const response = await base.api.delete(`${API_ROOT}/company/${company}/invitations/${invitationId}`);
  return response;
};

const createUser = async (payload) => {
  const company = sessionStorage.getItem(COMPANY_KEY);
  const response = await base.api.post(`${API_ROOT}/companies/${company}/users/`, payload);
  return response;
};

const createUserInvitation = async (payload) => {
  const company = sessionStorage.getItem(COMPANY_KEY);
  const response = await base.api.post(`${API_ROOT}/company/${company}/invite-user`, payload);
  return response;
};

const getUser = async (userId) => {
  const company = sessionStorage.getItem(COMPANY_KEY);
  const response = await base.api.get(`${API_ROOT}/company/${company}/users/${userId}`);
  return response;
};

const blockUser = async (userId) => {
  const company = sessionStorage.getItem(COMPANY_KEY);
  const response = await base.api.put(`${API_ROOT}/company/${company}/users/${userId}/block`);
  return response;
};

const unblockUser = async (userId) => {
  const company = sessionStorage.getItem(COMPANY_KEY);
  const response = await base.api.put(`${API_ROOT}/company/${company}/users/${userId}/unblock`);
  return response;
};

const updateUser = async (userId, payload) => {
  const company = sessionStorage.getItem(COMPANY_KEY);
  const response = await base.api.patch(`${API_ROOT}/company/${company}/users/${userId}`, payload);
  return response;
};

export const getOrgLeaderTitle = async () => {
  const company = sessionStorage.getItem(COMPANY_KEY);
  const response = await base.api.get(`${API_ROOT}/api/v2/companies/${company}/org-leader-titles`);
  return response;
};

export const updateOrgLeaderTitle = async (orgLeaderTitles) => {
  const company = sessionStorage.getItem(COMPANY_KEY);
  const response = await base.api.put(`${API_ROOT}/api/v2/companies/${company}/org-leader-titles`, {
    organization_leadership_titles: orgLeaderTitles,
  });
  return response;
};

const getCompanyVendors = async () => {
  const response = await base.api.get(`${CONFIG_SERVICE_ROOT}/v2/config/companies/user/vendors`);
  return response;
};

// Queries
export const ALL_USERS_QUERY_KEY = 'users';
export const ALL_INVITATIONS_QUERY_KEY = 'invitations';
export const ORG_LEADER_QUERY_KEY = 'org-leader-titles';
export const COMPANY_CONFIG_QUERY_KEY = 'company-configurations';

// Mutations
export const useCreateUserInvitationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((payload) => createUserInvitation(payload), {
    onSuccess: () => {
      void queryClient.invalidateQueries([ALL_USERS_QUERY_KEY]);
    },
  });
};

export const useBlockUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((payload) => blockUser(payload.userId), {
    onMutate: async (payload) => {
      // Optimisitc update
      await queryClient.cancelQueries([ALL_USERS_QUERY_KEY]);

      const previousUsers = queryClient.getQueryData([ALL_USERS_QUERY_KEY]);

      queryClient.setQueryData([ALL_USERS_QUERY_KEY], (oldData) => {
        return oldData.map((user) => (user.id === payload.userId ? { ...user, blocked: true } : user));
      });

      return { previousUsers };
    },
    onError: (err, payload, context) => {
      queryClient.setQueryData([ALL_USERS_QUERY_KEY], context.previousUsers);
    },
  });
};

export const useUnblockUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((payload) => unblockUser(payload.userId), {
    onMutate: async (payload) => {
      // Optimisitc update
      await queryClient.cancelQueries([ALL_USERS_QUERY_KEY]);

      const previousUsers = queryClient.getQueryData([ALL_USERS_QUERY_KEY]);

      queryClient.setQueryData([ALL_USERS_QUERY_KEY], (oldData) => {
        return oldData.map((user) => (user.id === payload.userId ? { ...user, blocked: false } : user));
      });

      return { previousUsers };
    },
    onError: (err, payload, context) => {
      queryClient.setQueryData([ALL_USERS_QUERY_KEY], context.previousUsers);
    },
  });
};

export const useUserPatchMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((payload) => updateUser(payload.userId, omit(payload, 'userId')), {
    onSuccess: () => {
      void queryClient.invalidateQueries([ALL_USERS_QUERY_KEY]);
    },
  });
};

export const useOrgLeaderTitlePutMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload) =>
      updateConfigurations({
        organizationLeadershipTitles: payload,
      }),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries([COMPANY_CONFIG_QUERY_KEY]);
      },
    },
  );
};

export const useRevokeUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((payload) => revokeUserInvitation(payload.invitationId), {
    onSuccess: (_, payload) => {
      const previousPermissionGroup = payload.permissionGroups.find(({ users }) =>
        users.some(({ userId }) => userId === payload.inviteeEmail),
      );

      if (previousPermissionGroup) {
        deleteUserFromPermissionGroup(payload.inviteeEmail, previousPermissionGroup.id).then();
      }
      void queryClient.invalidateQueries([ALL_INVITATIONS_QUERY_KEY]);
    },
  });
};

export const useUsersQuery = (options) => {
  return useQuery([USERS_QUERY_KEY], () => getUsers(), options);
};

export default {
  getCompanyConfigurations,
  updateConfigurations,
  getUsers,
  getUserInvitations,
  revokeUserInvitation,
  createUser,
  createUserInvitation,
  getUser,
  updateUser,
  getCompanyVendors,
  useUsersQuery,
  useCreateUserInvitationMutation,
};
