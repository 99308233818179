import { rest } from 'msw';

import { API_ROOT, CONFIG_SERVICE_ROOT } from 'config';

import { mockCompanyDomain, mockUserInvitations, mockUsers, mockVendors } from './mocks';

const handlers = [
  rest.get(`${CONFIG_SERVICE_ROOT}/v2/config/companies/user/vendors`, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockVendors));
  }),
  rest.get(`${API_ROOT}/api/v2/companies/:company/users/`, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockUsers));
  }),
  rest.get(`${API_ROOT}/api/v2/companies/${mockCompanyDomain}/user-invitations/`, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockUserInvitations));
  }),
  rest.delete(`${API_ROOT}/api/v2/companies/${mockCompanyDomain}/user-invitations/:invitationId`, (_req, res, ctx) => {
    return res(ctx.status(200));
  }),
  rest.patch(`${API_ROOT}/api/v2/companies/${mockCompanyDomain}/users/:userId`, (_req, res, ctx) => {
    return res(ctx.status(200));
  }),
];

export default handlers;
