// From old design system

import { red, grey, blue, orange, green, purple, cyan, gold, yellow, lime, magenta } from '@ant-design/colors';

// TODO: Deprecate 'oldColors' once all usage has been replaced by 'colors'
export const oldColors = {
  // Blue
  blue1: '#F4F8FF',
  blue3: '#A3C6FF',
  blue5: '#6499EF',
  blue6: '#5280DE',
  blue7: '#2B6AD0',
  blue8: '#1B57B7',
  blue9: '#0E408F',
  blue10: '#04265C',

  // Grays
  gray3: '#F4F6F8',
  gray6: '#B5BBC2',
  gray7: '#949BA3',
  gray8: '#5C6268',
};

// Design system colors: https://www.figma.com/file/8wDwbyaEoFQh7kJy6E7gG4/%F0%9F%8E%A8Knoetic-(Ant)-Design-System?node-id=449%3A5721
export const colors = {
  // Brand colors
  brandBlue: '#05205E',
  brandGreen: '#00D38D',
  brandGreen2: '#00C282',
  brandGreen3: '#00AD74',

  // NEW Functional colors
  info: '#05205E',
  notesText: grey[8],
  notesBorder: grey[4],
  notesBackground: grey[1],
  blueText: blue[6],
  warningText: orange[7],
  warningBorder: orange[3],
  warningBackground: orange[0],
  errorText: red[5],
  errorBorder: red[2],
  successText: green[7],
  successBorder: green[3],
  successBackground: green[0],
  tooltipBg: '#000000BF',
  extraTooltipBg: 'rgba(0, 0, 0, 0.75)',

  primary6: '#1890FF',

  blueBg: '#F9FAFE',
  blue0: '#F0F3FA',
  blue1: '#E5EDFF',
  blue2: '#B3CAFF',
  blue3: '#8BAEFF',
  blue4: '#5F8FFF',
  blue5: '#326CF0',
  blue6: '#0354D6',
  blue7: '#003FA5',
  blue8: '#002A76',
  blue9: '#00184A',

  // TODO: Check with Yung about the colors
  geekBlue1: '#F0F5FF',
  geekBlue6: '#2F54EB',

  green: '#008357',
  green1: green[0],
  green2: green[1],
  green3: green[2],
  green4: green[3],
  green5: green[4],
  green6: green[5],
  green7: green[6],
  green8: green[7],
  green9: green[8],
  green10: green[9],

  yellow: '#F7B32B',
  yellowHighlight: 'rgba(247, 179, 43, 0.25)',
  yellow1: yellow[0],
  yellow2: yellow[1],
  yellow3: yellow[2],
  yellow4: yellow[3],
  yellow5: yellow[4],
  yellow6: yellow[5],
  yellow7: yellow[6],
  yellow8: yellow[7],
  yellow9: yellow[8],
  yellow10: yellow[9],

  gray1: '#FFFFFF',
  gray2: '#FAFAFA',
  gray3: '#F5F5F5',
  gray4: '#F0F0F0',
  gray5: '#D9D9D9',
  gray6: '#BFBFBF',
  gray7: '#8C8C8C',
  gray8: '#595959',
  gray9: '#434343',
  gray10: '#262626',

  red1: red[0],
  red2: red[1],
  red3: red[2],
  red4: red[3],
  red5: red[4],
  red6: red[5],
  red7: red[6],
  red8: red[7],
  red9: red[8],
  red10: red[9],

  orange1: orange[0],
  orange2: orange[1],
  orange3: orange[2],
  orange4: orange[3],
  orange5: orange[4],
  orange6: orange[5],
  orange7: orange[6],
  orange8: orange[7],
  orange9: orange[8],
  orange10: orange[9],

  purple1: purple[0],
  purple2: purple[1],
  purple3: purple[2],
  purple4: purple[3],
  purple5: purple[4],
  purple6: purple[5],
  purple7: purple[6],
  purple8: purple[7],
  purple9: purple[8],
  purple10: purple[9],

  cyan1: cyan[0],
  cyan2: cyan[1],
  cyan3: cyan[2],
  cyan4: cyan[3],
  cyan5: cyan[4],
  cyan6: cyan[5],
  cyan7: cyan[6],
  cyan8: cyan[7],
  cyan9: cyan[8],
  cyan10: cyan[9],

  gold1: gold[0],
  gold2: gold[1],
  gold3: gold[2],
  gold4: gold[3],
  gold5: gold[4],
  gold6: gold[5],
  gold7: gold[6],
  gold8: gold[7],
  gold9: gold[8],
  gold10: gold[9],

  lime1: lime[0],
  lime2: lime[1],
  lime3: lime[2],
  lime4: lime[3],
  lime5: lime[4],
  lime6: lime[5],
  lime7: lime[6],
  lime8: lime[7],
  lime9: lime[8],
  lime10: lime[9],

  magenta1: magenta[0],
  magenta2: magenta[1],
  magenta3: magenta[2],
  magenta4: magenta[3],
  magenta5: magenta[4],
  magenta6: magenta[5],
  magenta7: magenta[6],
  magenta8: magenta[7],
  magenta9: magenta[8],
  magenta10: magenta[9],

  // Macaron Theme from AntV
  /*
   * macaron{x} are not the same color, they are named as such due to naming difficulties.
   * They are actually different high contrast colors
   * Figma link: https://www.figma.com/file/KPBJAcASrgP7rhU4XiRvb3/%F0%9F%8E%A8%2C-%F0%9F%9A%A7V2-Knoetic-(Ant)-Design-System?node-id=1287%3A30231
   */
  macaron1: '#CD71CA',
  macaron2: '#367C96',
  macaron3: '#D94F84',
  macaron4: '#51A77D',
  macaron5: '#63667F',
  macaron6: '#61B9C8',
  macaron7: '#C28459',
  macaron8: '#4B96CC',
  macaron9: '#8E6FF7',
  macaron10: '#F3ACAA',
  macaron11: '#8FBA9C',
  macaron12: '#773984',

  black: '#000000',
};
