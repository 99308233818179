// Cube names
export const APPLICATION_CUBE_NAME = 'Application';
export const CANDIDATE_CUBE_NAME = 'Candidate';
export const COMPENSATION_BONUS_CUBE_NAME = 'CompensationBonus';
export const COMPENSATION_CUBE_NAME = 'Compensation';
export const COMPENSATION_SALARY_CUBE_NAME = 'CompensationSalary';
export const DATA_ISSUE_CUBE_NAME = 'DataIssue';
export const DEPARTMENT_CUBE_NAME = 'Department';
export const DYNAMIC_PERFORMANCE_RATING_CUBE_NAME = 'DynamicPerformanceRating';
export const DIRECT_MANAGER_CUBE_NAME = 'DirectManager';
export const DIRECT_MANAGER_BRIDGE_CUBE_NAME = 'DirectManagerBridge';
export const EMPLOYEE_CUBE_NAME = 'Employee';
export const EMPLOYEE_IS_MANAGER_CUBE_NAME = 'EmployeeIsManager';
export const EMPLOYEE_ORG_LEVEL_CUBE_NAME = 'EmployeeOrgLevel';
export const EQUITY_CUBE_NAME = 'Equity';
export const HEADCOUNT_CUBE_NAME = 'Headcount';
export const HIRING_CUBE_NAME = 'Hiring';
export const HIRING_TEAM_CUBE_NAME = 'HiringTeam';
export const INTERVIEW_CUBE_NAME = 'Interview';
export const INTERVIEWER_CUBE_NAME = 'Interviewer';
export const JOB_CUBE_NAME = 'Job';
export const L2_LEADERS_CUBE_NAME = 'L2Leaders';
export const L3_LEADERS_CUBE_NAME = 'L3Leaders';
export const L4_LEADERS_CUBE_NAME = 'L4Leaders';
export const L5_LEADERS_CUBE_NAME = 'L5Leaders';
export const MANAGER_BRIDGE_CUBE_NAME = 'ManagerBridge';
export const MANAGER_CUBE_NAME = 'Manager';
export const OFFER_CUBE_NAME = 'Offer';
export const ORGANIZATION_CUBE_NAME = 'Organization';
export const ORGANIZATION_SNAPSHOT_CUBE_NAME = 'OrganizationSnapshot';
export const PERFORMANCE_RATING_CUBE_NAME = 'PerformanceRatingPrompt';
export const RECRUITING_CUBE_NAME = 'Recruiting';
export const REPORT_CUBE_NAME = 'Report';
export const REQUISITION_CUBE_NAME = 'Requisition';
export const REVIEW_CUBE_NAME = 'Review';
export const REVIEW_CYCLE_CUBE_NAME = 'ReviewCycle';
export const SCORECARD_CUBE_NAME = 'Scorecard';
export const RECRUITING_PIPELINE_CUBE_NAME = 'recruiting_pipeline';
export const EMPLOYEE_PROFILE_VIEW_NAME = 'employee_profile';
export const GREENHOUSE_CUBE_NAME = 'greenhouse';
export const BAMBOO_CUBE_NAME = 'bamboo';

// Cube descriptions
export const DATA_ISSUE_CUBE_DESCRIPTION = 'Data Issue';

// Regexes
export const LX_LEADERS_REGEX = /L\d+Leaders/i;
export const REPORT_REGEX = /(Report.)/i;

export const RECRUITING_SCHEMAS = [
  'application',
  'candidate',
  'hiring',
  'hiringteam',
  'interview',
  'interviewer',
  'job',
  'recruiting',
];
export const AVAILABLE_SCHEMAS = [
  'compensation',
  'compensationbonus',
  'compensationsalary',
  'dataissue',
  'department',
  'directmanager',
  'directmanagerbridge',
  'dynamicperformancerating',
  'employee',
  'employeeorglevel',
  'employeeismanager',
  'equity',
  'headcount',
  'l2leaders',
  'l3leaders',
  'l4leaders',
  'l5leaders',
  'manager',
  'managerbridge',
  'offer',
  'organization',
  'organization_snapshot',
  'performanceratingprompt',
  'report',
  'requisition',
  'review',
  'reviewcycle',
  'scorecard',
  ...RECRUITING_SCHEMAS,
  //   'jobpost',
  //   'goal',
  //   'feedback',
];
