import { ArticleType } from 'constants/article';

import articleTypeImage from 'static/cpohq/Article.svg';
import playbookTypeImage from 'static/cpohq/Playbook.svg';
import templateTypeImage from 'static/cpohq/Template.svg';

export const getImage = (type: string) => {
  if (!type) {
    return articleTypeImage;
  }

  // To make the switch cases not be affected by case
  const normalizedType = type.toLowerCase();

  switch (normalizedType) {
    case ArticleType.ARTICLE:
      return articleTypeImage;
    case ArticleType.PLAYBOOK:
      return playbookTypeImage;
    case ArticleType.TEMPLATE:
      return templateTypeImage;
    default:
      return articleTypeImage;
  }
};

/**
 * Get the media URL for the given file path through a CDN
 * @param filepath The file path in the s3 bucket
 * @param bucket The bucket name to use for the media URL
 * @returns The media URL for retrieval with a CDN
 */
export const getMediaUrl = (filepath: string, bucket?: string) => {
  if (bucket) {
    return `https://${bucket}.s3.us-east-1.amazonaws.com/${filepath}`;
  }

  if (filepath.startsWith('http')) {
    return filepath;
  }

  const cdnUrl = new URL('https://d2kj62gurl1ldb.cloudfront.net');
  cdnUrl.pathname = filepath;
  return cdnUrl.toString();
};
