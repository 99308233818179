import React from 'react';
import stylePropType from 'react-style-proptype';

import { LoadingOutlined } from '@ant-design/icons';
import { Row, Spin, Space, Typography } from 'antd';
import PropTypes from 'prop-types';

const { Text } = Typography;

const Loader = ({ iconSize = 24, spaceSize = 16, loadingText = '', direction = 'vertical', loaderColor, style }) => {
  const antIcon = <LoadingOutlined spin style={{ fontSize: iconSize }} />;
  return (
    <Row justify="center" align="middle" style={{ height: '100%', ...style }}>
      <Space direction={direction} size={spaceSize} align="center">
        <Spin indicator={antIcon} style={loaderColor ? { color: loaderColor } : {}} />
        {loadingText && <Text type="secondary">{loadingText}</Text>}
      </Space>
    </Row>
  );
};

Loader.propTypes = {
  iconSize: PropTypes.number,
  spaceSize: PropTypes.number,
  loadingText: PropTypes.string,
  loaderColor: PropTypes.string,
  direction: PropTypes.oneOf(['vertical', 'horizontal']),
  style: stylePropType,
};

export default Loader;
