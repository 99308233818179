import React from 'react';

import PropTypes from 'prop-types';

import Loader from './Loader';

const FullPageLoader = ({ loadingText = '' }) => {
  return (
    <div style={{ width: '100vw', height: '100vh' }} className="centered-wrapper">
      <Loader loadingText={loadingText} />
    </div>
  );
};

FullPageLoader.propTypes = {
  loadingText: PropTypes.string,
};

export default FullPageLoader;
