import React from 'react';

import { CloseCircleOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import { Modal } from 'antd';
import axios from 'axios';
import queryString from 'query-string';

import { AUTH0_AUDIENCE } from 'config';
import routeMap from 'routeMap';

import { colors } from '../colors';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.withCredentials = true;

class HttpClient {
  constructor() {
    this.modal = null;
    this.api = axios.create({
      paramsSerializer: (params) => {
        return queryString.stringify(params);
      },
    });
    // Whenever a request is made, we attach our token in header.
    this.api.interceptors.request.use(async (config) => {
      const token = await this.getToken();
      if (token) {
        return { ...config, headers: { ...config.headers, Authorization: `Bearer ${token}` } };
      }
      return config;
    });

    // Whenever a response is received
    this.api.interceptors.response.use(
      (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        return response.data;
      },
      (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // error.response.data
        if (error?.response?.status === 403) {
          const reason =
            error?.response?.data.reason ||
            'Sorry, but you do not have permission to access the contents of this page.';
          if (this.modal) this.modal.destroy();
          this.modal = Modal.confirm({
            title: 'Access Forbidden',
            content: reason,
            cancelButtonProps: {
              danger: true,
            },
            cancelText: 'Log Out',
            onCancel: (close) => {
              this.modal = null;
              close();
              this.logout({ logoutParams: { returnTo: window.location.origin } });
            },
            icon: <CloseCircleOutlined style={{ color: colors.red3 }} />,
            okText: 'Back to Dashboard',
            onOk: (close) => {
              this.modal = null;
              close();
              window.location.replace(routeMap.home);
            },
          });
        }
        return Promise.reject(error);
      },
    );

    return this;
  }

  setTokenGenerator(tokenGenerator) {
    this.tokenGenerator = tokenGenerator;
    return this;
  }

  setLogoutMethod(logout) {
    this.logout = logout;
    return this;
  }

  async getToken() {
    try {
      return await this.tokenGenerator({ audience: AUTH0_AUDIENCE });
    } catch (err) {
      if (err.message === 'Login required') {
        Sentry.addBreadcrumb('HttpClient.getToken');
        Sentry.captureException(err);
        this.logout?.();
      }
      return null;
    }
  }
}

export default new HttpClient();
