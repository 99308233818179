import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { relayStylePagination } from '@apollo/client/utilities';
import { cloneDeep } from 'lodash';

import base from 'api/base';
import { GRAPHQL_ROOT } from 'config';

const httpLink = createHttpLink({
  uri: ({ operationName }) => {
    return `${GRAPHQL_ROOT}?operationName=${operationName || 'unknown'}`;
  },
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists

  const token = await base.getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const mergeRefs = (existing = [], incoming = []) => {
  const existingRefs = new Set(existing.map((ref) => ref.__ref));
  const incomingUniqueRefs = incoming.filter((ref) => !existingRefs.has(ref.__ref));
  return [...existing, ...incomingUniqueRefs];
};

export const initializeInMemoryCache = () =>
  new InMemoryCache({
    typePolicies: {
      Post: {
        fields: {
          memberVotes: {
            merge(_, incoming = []) {
              return incoming;
            },
          },
        },
      },
      Member: {
        fields: {
          posts: {
            keyArgs: false,
            merge(existing = { items: [] }, incoming) {
              return {
                ...existing,
                ...incoming,
                items: existing.items.concat(incoming.items),
              };
            },
          },
          replies: {
            keyArgs: false,
            merge(existing = { items: [] }, incoming) {
              return {
                ...existing,
                ...incoming,
                items: existing.items.concat(incoming.items),
              };
            },
          },
          likedArticles: {
            keyArgs: false,
            merge(_, incoming = []) {
              return incoming;
            },
          },
          pollAnswers: {
            keyArgs: false,
            merge(_, incoming = []) {
              return incoming;
            },
          },
          votedPosts: {
            keyArgs: false,
            merge(existing = { items: [] }, incoming) {
              return {
                ...existing,
                ...incoming,
                items: existing.items.concat(incoming.items),
              };
            },
          },
          followers: {
            keyArgs: false,
            merge(existing = { items: [] }, incoming) {
              return {
                ...existing,
                ...incoming,
                items: existing.items.concat(incoming.items),
              };
            },
          },
          followedMembers: {
            keyArgs: false,
            merge(existing = { items: [] }, incoming) {
              return {
                ...existing,
                ...incoming,
                items: existing.items.concat(incoming.items),
              };
            },
          },
          notifications: {
            keyArgs: false,
            merge(existing, incoming) {
              return mergeRefs(existing, incoming);
            },
          },
          collections: {
            keyArgs: false,
            merge(_, incoming = []) {
              return incoming;
            },
          },
          events: {
            keyArgs: false,
            merge(existing = { items: [] }, incoming) {
              return {
                ...existing,
                ...incoming,
                items: existing.items.concat(incoming.items),
              };
            },
          },
          _count: {
            merge(existing, incoming) {
              return { ...existing, ...incoming };
            },
          },
        },
      },
      // Poll: {
      //   keyFields: ['id'], // Normalize Poll objects by their unique `id`
      // },
      Query: {
        fields: {
          getFeed: relayStylePagination(['queryType']),
          getPolls: {
            keyArgs: ['status'], // Use `status` to differentiate queries
            merge(existing = {}, incoming) {
              const existingPolls = existing.polls || [];
              const incomingPolls = incoming.polls || [];

              // Deduplicate polls by `id`
              const mergedPolls = [
                ...existingPolls.filter((poll) => !incomingPolls.some((newPoll) => newPoll.id === poll.id)),
                ...incomingPolls,
              ];

              return {
                ...incoming,
                polls: mergedPolls,
              };
            },
          },
          getAllPosts: {
            keyArgs: false,
            merge(existing = { posts: [], cursor: { id: 0, type: 'PINNED' } }, incoming, { args: { cursor } }) {
              if (cursor.id === 0 && cursor.type === 'PINNED') return incoming;

              return {
                posts: mergeRefs(existing.posts, incoming.posts),
                cursor: incoming.cursor,
              };
            },
          },
          getMainPosts: {
            keyArgs: ['orderBy', 'take'],
            merge(existing = [], incoming) {
              return mergeRefs(existing, incoming);
            },
          },
          getMembers: {
            keyArgs: ['searchString', 'stageFilters', 'sizeFilters', 'locationFilters'],
            merge(existing = { members: [] }, incoming) {
              return {
                ...existing,
                ...incoming,
                members: mergeRefs(existing.members, incoming.members),
              };
            },
          },
          search: {
            keyArgs: ['searchString', 'type', 'sortBy', 'tags', 'contentTypeId'],
            merge(existing, incoming) {
              const merged = cloneDeep(incoming);
              merged.posts = mergeRefs(existing?.posts, incoming.posts);
              merged.replies = mergeRefs(existing?.replies, incoming.replies);
              merged.polls = mergeRefs(existing?.polls, incoming.polls);
              merged.articles = mergeRefs(existing?.articles, incoming.articles);

              return merged;
            },
          },
          getArticles: {
            keyArgs: ['filter'],
            merge(existing, incoming) {
              if (incoming && existing) {
                return {
                  ...existing,
                  ...incoming,
                  articles: mergeRefs(existing.articles, incoming.articles),
                };
              }
              return incoming;
            },
          },
          getEvents: {
            keyArgs: ['filters'],
            merge(existing, incoming) {
              if (incoming && existing) {
                return {
                  ...existing,
                  ...incoming,
                  events: mergeRefs(existing.events, incoming.events),
                };
              }
              return incoming;
            },
          },
          getChangelogs: {
            keyArgs: ['filter'],
            merge(existing, incoming) {
              if (incoming && existing) {
                const mergedRefs = mergeRefs(existing.items, incoming.items);
                return {
                  ...existing,
                  ...incoming,
                  items: mergedRefs,
                };
              }
              return incoming;
            },
          },
          getEventReplies: {
            keyArgs: ['eventId'],
            merge(existing, incoming) {
              if (incoming && existing) {
                return mergeRefs(existing, incoming, 'prepend');
              }
              return incoming;
            },
          },
        },
      },
      Collection: {
        fields: {
          posts: {
            read(refs = [], { readField }) {
              return [...refs].sort((refA, refB) => {
                const dateA = new Date(Number.parseInt(readField('createdAt', refA), 10));
                const dateB = new Date(Number.parseInt(readField('createdAt', refB), 10));
                return dateB - dateA;
              });
            },
            merge(_existing, incoming) {
              return incoming;
            },
          },
          articles: {
            read(refs = [], { readField }) {
              return [...refs].sort((refA, refB) => {
                const dateA = new Date(Number.parseInt(readField('publishedDate', refA), 10));
                const dateB = new Date(Number.parseInt(readField('publishedDate', refB), 10));
                return dateB - dateA;
              });
            },
            merge(_existing, incoming) {
              return incoming;
            },
          },
          polls: {
            read(refs = [], { readField }) {
              return [...refs].sort((refA, refB) => {
                const dateA = new Date(Number.parseInt(readField('publishedDate', refA), 10));
                const dateB = new Date(Number.parseInt(readField('publishedDate', refB), 10));
                return dateB - dateA;
              });
            },
            merge(_existing, incoming) {
              return incoming;
            },
          },
          replies: {
            read(refs = [], { readField }) {
              return [...refs].sort((refA, refB) => {
                const dateA = new Date(Number.parseInt(readField('createdAt', refA), 10));
                const dateB = new Date(Number.parseInt(readField('createdAt', refB), 10));
                return dateB - dateA;
              });
            },
            merge(_existing, incoming) {
              return incoming;
            },
          },
        },
      },
    },
  });

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: initializeInMemoryCache(),
});

export default client;
