export type Auth0Roles = {
  CPOHQ_FORUM_ADMIN: string;
  CPOHQ_MEMBER: string;
  ORG_GUEST_USER: string;
  ORG_NORMAL_USER: string;
  ORG_ADMIN_USER: string;
  GENIE_2_USER: string;
  BENCHMARKS_ONLY_USER: string;
};

export const auth0Roles: Auth0Roles = {
  CPOHQ_FORUM_ADMIN: 'cpohq_forum_admin',
  CPOHQ_MEMBER: 'cpohq_member',
  ORG_GUEST_USER: 'org_guest',
  ORG_NORMAL_USER: 'org_user',
  ORG_ADMIN_USER: 'org_admin',
  GENIE_2_USER: 'genie_2_user',
  BENCHMARKS_ONLY_USER: 'benchmarks_only',
} as const;
