import { generate } from '@ant-design/colors';
import { registerTheme } from 'bizcharts';

import { colors } from 'colors';

const defaultChartThemeColors = [
  colors.blue5,
  colors.blue2,
  colors.brandGreen,
  colors.blue6,
  colors.yellow,
  colors.brandBlue,
  colors.green,
  colors.red3,
  colors.macaron1,
  colors.macaron2,
  colors.macaron3,
  colors.macaron4,
  colors.macaron5,
  colors.macaron6,
  colors.macaron7,
  colors.macaron8,
  colors.macaron9,
  colors.macaron10,
  colors.macaron11,
  colors.macaron12,
];

export const APP_THEME = 'appTheme';

export const MAX_DERIVED_COLORS = 5;

let currentColorPalette = defaultChartThemeColors;

type ColorMap = {
  _next: number;
  _reg: { [key: string]: number };
};

const colorMapProto = {
  _next: 0,
  _reg: {
    // '[No value]': -1, // lock the color for no value in all dimensions // commented out for now to allow for dynamic color assignment
  },
};
const colorsMap: { [key: string]: ColorMap } = {};
/**
 * Register the color for the key when it appears for the first time.
 * After that, the color should be reserved for the value under the same field.
 */
function registerColor(field: string, key: string) {
  let map = colorsMap[field];
  if (!map) {
    // initialize a new map by copying the prototype
    map = JSON.parse(JSON.stringify(colorMapProto)) as ColorMap;
    colorsMap[field] = map;
  }
  let colorIndex = map._reg[key];
  if (colorIndex === undefined) {
    // assign a color
    colorIndex = map._next++;
    map._reg[key] = colorIndex;
  }

  return currentColorPalette[(currentColorPalette.length + colorIndex) % currentColorPalette.length];
}

const colorKeyMask = /\s+\([^)]+\)$/;
/**
 * Return color for a value under the field.
 * If the value is a string, the func will automatically drop the (num) at the tail.
 */
export function getColorForValue(field: string, value: string | number | null | boolean) {
  // undefined?.toString() and null?.toString() return undefined
  // because we need string 'undefined' and 'null' not ref undefined,
  // use value+''
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands, prefer-template
  const key = typeof value === 'string' ? value.replace(colorKeyMask, '') : value + '';
  return registerColor(field, key);
}

/**
 * Get the value from the data object for the field, return the color for the value
 */
export function getColorForLabel(
  valueField: string,
  field: string,
  obj: { [key: string]: string | number | null | boolean },
) {
  return getColorForValue(field, obj[valueField]);
}

export function getColorPalette(selectedColors: string[] = [], repeat = false) {
  if (selectedColors.length === 0) {
    return defaultChartThemeColors;
  }

  if (repeat) {
    // If repeat is true, we'll just cycle through the selectedColors
    return selectedColors;
  }

  // generate color schemes from company colors
  let colorSchemes: string[] = [];
  selectedColors.forEach((color) => {
    colorSchemes = colorSchemes.concat(generate(color).slice(0, MAX_DERIVED_COLORS));
  });

  const shuffledColorSchemes: string[] = [];

  // shuffle the colorSchemes
  for (let i = MAX_DERIVED_COLORS - 1; i >= 0; i--) {
    for (let j = 0; j < selectedColors.length; j++) {
      shuffledColorSchemes.push(colorSchemes[i + MAX_DERIVED_COLORS * j]);
    }
  }

  return selectedColors.concat(shuffledColorSchemes);
}

export function registerChartTheme(colorSchemes: string[], repeat = false) {
  currentColorPalette = getColorPalette(colorSchemes, repeat);
  registerTheme(APP_THEME, {
    colors20: currentColorPalette,
    colors10: currentColorPalette.slice(0, 10),
    geometries: {
      interval: {
        rect: {
          default: { style: { fill: currentColorPalette[0] } },
          active: { style: { fillOpacity: 0.5, lineWidth: 0 } },
          selected: { style: { lineWidth: 0 } },
        },
      },
    },
  });
}

export default registerChartTheme;
